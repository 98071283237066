.recipes-display {
  margin: 10px;
  height: 70vh;
  width: 100%;
}

.recipe-card {
  height: 300px;
  width: 100%;
  margin: auto;
}
