.navBarRoute {
    display: flex;
    flex-direction: row;
    margin-right: 2;
    font-family: monospace;
    font-weight: 700;
    letter-spacing: .3rem;
    color: inherit;
    text-decoration: none;
    padding: 0 5px;
}
.navBarRoute:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}